import axios from 'axios'

import logo from './logo.svg';
import './App.css';
import React, {useEffect, useState} from "react";

function App() {
  const [dbgContent, setDbgContent] = useState(null);

  const getBackendDbgContent = () => {
    try {
      axios.defaults.headers.common['Accept'] = 'application/json';
      axios.defaults.headers.common['Content-Type'] = 'application/json';

      axios
          .get("https://api.creative-space.nliwod.org/", )
          .then((response) => {
            setDbgContent(response.data["debug-msg"]);
          });

    } catch (error) {
      console.log(">>>>>> " + error);
    }
  }

  useEffect(() => {
    getBackendDbgContent();
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          {dbgContent}
        </p>
      </header>
    </div>
  );
}

export default App;
